import Header from '../components/header';
import BlogComp from '../components/blog';
import Footer from "../components/footer";

const Blog = () => {
    return (
        <>
            <Header></Header>
             <BlogComp></BlogComp>
            
            <Footer></Footer>
        </>
    )
}

export default Blog;