import Header from '../components/header';
import AboutPage from '../components/aboutPage';
import Footer from "../components/footer";

const About = () => {
    return (
        <>
            <Header></Header>
             <AboutPage></AboutPage>
            
            <Footer></Footer>
        </>
    )
}

export default About;