import Header from '../components/header';
import Banner from '../components/banner';
import ContactComp from '../components/contact';
// import Portfolio from "../components/portfolio";
import Footer from "../components/footer";

const Contact = () => {
    return (
        <>
            <Header></Header>
            <ContactComp></ContactComp>
            <Footer></Footer>
        </>
    )
}

export default Contact;